import IconCover from './IconCover';
import React from 'react';
import {
  Box,
  Flex,
  FlexProps,
  Heading,
  Icon,
  Text
  } from '@chakra-ui/react';
import { FaTimes } from 'react-icons/fa';

type ErrorDetailsProps = {
  icon?: React.ReactNode;
  title: string;
  description?: string | JSX.Element;
} & FlexProps;

const ErrorDetails: React.FC<ErrorDetailsProps> = ({
  icon,
  title,
  description,
  children,
  ...rest
}) => (
  <Flex flexDir="column" align="center" textAlign="center" {...rest}>
    <IconCover>{icon || <Icon as={FaTimes} w="8" h="8" />}</IconCover>

    <Heading mt="6" fontWeight="800" fontSize="xl" maxW="12em">
      {title}
    </Heading>

    {description && (
      <Text mt="3" lineHeight="tall">
        {description}
      </Text>
    )}

    {children ? (
      <Box w="full" mt={6}>
        {children}
      </Box>
    ) : null}
  </Flex>
);

export default ErrorDetails;
