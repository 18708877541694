import React from 'react';
import ErrorPopup from 'components/ErrorPopup';
import { Button } from '@chakra-ui/react';
import { useConnect, useNetwork } from 'wagmi';
import { chains } from 'config';
import useTranslate from 'hooks/useTranslate';

const WalletErrors: React.FC = () => {
  const translate = useTranslate();
  const [
    {
      data: { connected: isConnected },
    },
  ] = useConnect();
  const [network, switchNetwork] = useNetwork();
  const isWrongNetworkErrorOpen =
    isConnected && !!network.data.chain?.unsupported;

  return (
    <>
      <ErrorPopup
        isOpen={isWrongNetworkErrorOpen}
        title={translate('wallet:error:wrongChain:title')}
        description={translate('wallet:error:wrongChain:description', {
          chainName: chains[0].name,
        })}
      >
        {switchNetwork && (
          <Button
            onClick={() => {
              switchNetwork(chains[0].id);
            }}
            w="full"
            colorScheme="secondary"
          >
            {translate('wallet:error:wrongChain:switch')}
          </Button>
        )}
      </ErrorPopup>
    </>
  );
};

export default WalletErrors;
