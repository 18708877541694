import Logo from 'components/Logo';
import MetaMaskIcon from 'components/MetaMaskIcon';
import React from 'react';
import useSnackbarError from 'hooks/useSnackbarError';
import useTranslate from 'hooks/useTranslate';
import WalletConnectIcon from 'components/WalletConnectIcon';
import { useConnect } from 'wagmi';
import {
  Box,
  Button,
  Center,
  Container,
  Heading,
  Icon,
  VStack,
} from '@chakra-ui/react';

const connectorIcons: Record<string, React.FC> = {
  MetaMask: MetaMaskIcon,
  WalletConnect: WalletConnectIcon,
};

const ConnectPage: React.FC = () => {
  const translate = useTranslate();

  const [{ data: connectData, error }, connect] = useConnect();
  useSnackbarError(error);

  return (
    <Center h="full" pt="12" pb="16">
      {' '}
      <Container>
        <VStack
          maxH="xl"
          maxW="sm"
          align="center"
          spacing="16"
          color="dark.900"
        >
          <Box as={Logo} w="52" h="auto" />

          <Box p="8" bg="dark.800" borderRadius="lg" color="white">
            <Heading fontSize="xl" w="full" textAlign="center" mb="6" mt="1">
              {translate('connect:title')}
            </Heading>

            <VStack w="full" align="stretch" spacing="4">
              {connectData.connectors.map(connector => (
                <Button
                  key={connector.id}
                  onClick={() => connect(connector)}
                  isDisabled={!connector.ready}
                  size="lg"
                  w="full"
                  iconSpacing="3"
                  variant="outline"
                  colorScheme="primary"
                  borderRadius="full"
                  leftIcon={
                    <Icon as={connectorIcons[connector.name]} w="8" h="8" />
                  }
                >
                  {translate('connect:button:label', {
                    connector: connector.name,
                  })}
                </Button>
              ))}
            </VStack>
          </Box>
        </VStack>
      </Container>
    </Center>
  );
};

export default ConnectPage;
