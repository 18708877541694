import React from 'react';

const Logo: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="84"
    height="27"
    viewBox="0 0 84 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.978.37C1.85.37 0 1.81 0 4.73c0 3.662 3.743 9.092 3.743 14.645 0 1.852-1.234 4.073-2.632 5.266-.412.37-.617.864-.617 1.152 0 .535.329.823 1.028.823 1.193 0 2.51-.124 4.566-.124 2.345-.041 4.895.206 6.829.206 1.275 0 1.769-.576 1.769-1.358 0-3.126-9.091-4.772-9.091-10.448 0-1.194.534-1.893 1.604-1.893 2.51 0 5.43 3.95 8.268 3.95 2.715 0 3.538-2.14 3.538-4.855 0-1.193-.452-1.563-1.028-1.563-.946 0-2.222 1.316-5.718 1.316-2.345 0-9.79-1.727-9.79-5.306 0-1.317.78-2.18 2.673-2.18 3.291 0 7.775 3.702 11.477 3.702 2.92 0 3.95-1.81 3.95-4.854 0-1.399-.37-3.209-1.523-3.209-.864 0-1.604 1.48-3.825 1.48C12.259 1.48 7.65.37 4.978.37Z"
      fill="currentColor"
    />
    <path
      d="M22.758.37c-.658 0-1.193.453-1.193 1.234 0 2.14 3.538 3.744 3.538 9.133 0 4.854-3.826 12.053-3.826 14.397 0 1.276.658 1.605 1.563 1.605 1.934 0 3.95-.7 7.446-.7 2.468 0 7.199.7 9.132.7 4.155 0 5.883-2.304 5.883-6.87 0-4.36-2.304-5.389-5.924-5.636-1.193-.082-1.892-.288-1.892-.946 0-2.016 5.553-3.126 5.553-8.515 0-2.51-1.892-4.607-4.73-4.607-3.497 0-4.567 1.316-8.104 1.316-3.373 0-5.348-1.11-7.446-1.11Zm8.433 14.192c5.224 0 7.363 2.057 7.363 4.69 0 3.126-1.522 4.402-4.483 4.402-5.595 0-7.981-2.674-7.981-5.307 0-2.386 1.728-3.785 5.1-3.785Zm.288-11.148c3.167 0 4.73 1.81 4.73 5.142 0 2.55-1.069 3.99-3.496 3.99-4.525 0-5.883-3.578-5.883-5.594 0-2.098 1.358-3.538 4.649-3.538Z"
      fill="currentColor"
    />
    <path
      d="M56.646 2.797c-2.14 0-4.566-2.632-7.405-2.632-1.151 0-1.933.493-1.933 1.48 0 3.415 3.414 6.623 3.414 10.532 0 4.813-3.579 11.148-3.579 13.163 0 .823.412 1.317 1.152 1.317.823 0 2.51-.124 3.908-.124 1.399 0 3.209.206 3.62.206.74 0 1.193-.37 1.193-1.152 0-3.702-3.99-8.063-3.99-13.657 0-3.497 1.398-6.747 3.538-6.747 3.208 0 6.088 7.035 6.088 10.325 0 4.567-2.633 6.912-2.633 9.668 0 1.316.535 1.604 4.196 1.604 5.389 0 6.047-.37 6.047-1.646 0-2.427-5.43-4.03-5.43-9.296 0-6.047 2.962-10.49 6.17-10.49 2.716 0 3.744 1.316 3.703 4.113.041 5.143-2.88 8.187-2.88 12.506 0 3.044 2.098 4.813 5.636 4.813 4.114 0 5.965-2.838 5.965-6.459 0-1.933-.658-2.756-1.234-2.756-.987 0-1.646 1.77-3.908 1.77-1.275 0-3.003-.371-3.003-2.099 0-3.29 5.882-5.677 5.923-12.464-.04-2.962-2.056-4.649-4.648-4.649-4.607 0-7.117 4.484-9.297 4.484-1.028 0-1.193-.411-1.316-1.44-.165-1.604-.823-3.002-2.88-3.002-2.221 0-3.908 2.632-6.417 2.632Z"
      fill="currentColor"
    />
  </svg>
);

export default Logo;
