import ErrorPopup from 'components/ErrorPopup';
import React, { useState } from 'react';
import useClaimActive from 'hooks/useClaimActive';
import useTranslate from 'hooks/useTranslate';
import { Button, useInterval } from '@chakra-ui/react';
import { honeyJarsOpenSeaUrl } from 'config';

const SECONDS_TO_REFRESH = 30;

const DisabledClaimPopup: React.FC = () => {
  const translate = useTranslate();
  const isClaimActive = useClaimActive();
  const [count, setCount] = useState<number>(SECONDS_TO_REFRESH);
  const [isCounting, setIsCounting] = useState<boolean>(true);

  useInterval(
    () => {
      if (count > 1) {
        setCount(count - 1);
      } else {
        setIsCounting(false);
      }
    },
    isCounting ? 1000 : null
  );

  return isClaimActive !== undefined && !isClaimActive ? (
    <ErrorPopup
      isOpen={!isClaimActive}
      title={translate('claim:disabledPopup:title')}
      description={translate('claim:disabledPopup:description')}
    >
      <Button
        as="a"
        href={honeyJarsOpenSeaUrl}
        target="_blank"
        w="full"
        colorScheme="secondary"
      >
        {translate('claim:disabledPopup:buy')}
      </Button>
    </ErrorPopup>
  ) : null;
};

export default DisabledClaimPopup;
