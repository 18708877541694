import { useMemo } from 'react';
import { goldlist } from 'config';

const useGoldlistEntry = (
  address?: string
): [address: string, maxClaim: number] | undefined =>
  useMemo(
    () => (address ? goldlist.find(entry => entry[0] === address) : undefined),
    [address]
  );

export default useGoldlistEntry;
