import DiscordIcon from 'components/DiscordIcon';
import OpenseaIcon from 'components/OpenseaIcon';
import React from 'react';
import TraitSwapLogo from 'components/TraitSwapLogo';
import TwitterIcon from 'components/TwitterIcon';
import useTranslate from 'hooks/useTranslate';
import { honeyJarsContract } from 'config';
import {
  Box,
  Button,
  Center,
  Container,
  Divider,
  HStack,
  Icon,
  Link,
  LinkBox,
  LinkOverlay,
  Text,
} from '@chakra-ui/react';

const Footer: React.FC = () => {
  const translate = useTranslate();

  return (
    <Box as="footer" mt="auto" bg="dark.900" py="4">
      <Container>
        <HStack justify="space-between">
          <HStack spacing="5">
            <Link
              href="//opensea.io/collection/fancybearsmetaverseofficial"
              target="_blank"
              display="flex"
              alignItems="center"
            >
              <Icon as={OpenseaIcon} w="7" h="auto" />
            </Link>

            <Link
              href="//discord.gg/fancybearsmetaverse"
              target="_blank"
              display="flex"
              alignItems="center"
            >
              <Icon as={DiscordIcon} w="7" h="auto" />
            </Link>

            <Link
              href="//twitter.com/FancyBearsMeta"
              target="_blank"
              display="flex"
              alignItems="center"
            >
              <Icon as={TwitterIcon} w="7" h="auto" />
            </Link>
          </HStack>

          <HStack justify="space-between" spacing="6">
            <Link
              href={`https://etherscan.io/address/${honeyJarsContract.address}`}
              target="_blank"
            >
              <Button as="span" variant="link" fontSize="sm">
                {translate('footer:viewContract')}
              </Button>
            </Link>
          </HStack>
        </HStack>

        <Divider my="2.5" />

        <LinkBox as={Center}>
          <Text fontSize="sm" fontWeight="semibold" mr="1.5">
            {translate('footer:powered')}
          </Text>
          <LinkOverlay href="//traitswap.com" target="_blank">
            <TraitSwapLogo width="65" height="auto" />
          </LinkOverlay>
        </LinkBox>
      </Container>
    </Box>
  );
};

export default Footer;
