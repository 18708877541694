import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Icon,
  Box,
  Text,
  ModalCloseButton,
  Heading,
} from '@chakra-ui/react';
import IconCover from 'components/IconCover';
import { FaTimes } from 'react-icons/fa';

export interface ErrorPopupProps {
  isOpen: boolean;
  icon?: React.ReactNode;
  title: string;
  description?: string;
  onClose?: () => void;
}

const ErrorPopup: React.FC<ErrorPopupProps> = ({
  isOpen,
  icon,
  title,
  description,
  onClose,
  children,
}) => (
  <Modal
    isOpen={isOpen}
    {...(onClose
      ? {
          onClose,
        }
      : {
          closeOnOverlayClick: false,
          closeOnEsc: false,
          onClose: () => {},
        })}
    autoFocus={false}
    size="sm"
  >
    <ModalOverlay />

    <ModalContent px={4} py={4}>
      {onClose && <ModalCloseButton />}

      <ModalBody
        display="flex"
        flexDir="column"
        alignItems="center"
        textAlign="center"
      >
        <IconCover>{icon || <Icon as={FaTimes} w="8" h="8" />}</IconCover>

        <Heading mt="6" fontWeight="800" fontSize="xl">
          {title}
        </Heading>

        {description && (
          <Text mt="3" lineHeight="tall" color="bright.400" opacity={0.7}>
            {description}
          </Text>
        )}

        {children ? (
          <Box w="full" mt={6}>
            {children}
          </Box>
        ) : null}
      </ModalBody>
    </ModalContent>
  </Modal>
);

export default ErrorPopup;
