/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import useTranslate from 'hooks/useTranslate';
import useSnackbar from 'hooks/useSnackbar';

const useSnackbarSuccess = (res?: any) => {
  const translate = useTranslate();
  const snackbar = useSnackbar();

  useEffect(() => {
    if (res) {
      snackbar('success', res?.message || translate('success:default'));
    }
  }, [res]);
};

export default useSnackbarSuccess;
