import { BigNumber } from 'ethers';
import { honeyJarsContract } from 'config';
import { useContractRead } from 'wagmi';
import { useEffect, useMemo, useState } from 'react';

const useGoldlistClaimedCount = (
  address?: string
): [
  claimedCount: number | undefined,
  readGoldlistClaimedCount: () => void
] => {
  const [claimedCount, setClaimedCount] = useState<number>();

  const [{ data: claimedData }, readGoldlistClaimedCount] = useContractRead(
    {
      addressOrName: honeyJarsContract.address,
      contractInterface: honeyJarsContract.interface,
    },
    'numberClaimedByGoldListAddress',
    {
      args: address,
    }
  );

  useEffect(() => {
    if (claimedData) {
      setClaimedCount((claimedData as any as BigNumber).toNumber());
    }
  }, [claimedData]);

  return useMemo(
    () => [claimedCount,  readGoldlistClaimedCount],
    [claimedCount, readGoldlistClaimedCount]
  );
};

export default useGoldlistClaimedCount;
