import { useToast } from '@chakra-ui/react';
import { useCallback } from 'react';
import SnackbarAlert, { SnackbarAlertProps } from 'components/SnackbarAlert';

const useSnackbar = () => {
  const toast = useToast();

  return useCallback(
    (
      status: SnackbarAlertProps['status'],
      message: string,
      duration = 5000
    ) => {
      toast({
        duration,
        isClosable: true,
        render: ({ onClose }) => (
          <SnackbarAlert status={status} message={message} onClose={onClose} />
        ),
      });
    },
    [toast]
  );
};

export default useSnackbar;
