import React from 'react';
import { Box } from '@chakra-ui/react';
import Footer from 'components/Footer';

type LayoutProps = {};

const Layout: React.FC<LayoutProps> = ({ children }) => (
  <Box h="full" display="flex" flexDirection="column" justifyContent="stretch">
    {children}
    <Footer />
  </Box>
);

export default Layout;
