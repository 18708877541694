import keccak256 from 'keccak256';
import useSnackbarError from 'hooks/useSnackbarError';
import useSnackbarSuccess from 'hooks/useSnackbarSuccess';
import { goldlist, honeyJarsContract } from 'config';
import { MerkleTree } from 'merkletreejs';
import { soliditySha3 } from 'web3-utils';
import { useContractWrite, useWaitForTransaction } from 'wagmi';
import { useEffect, useMemo } from 'react';

const useGoldlistMint = (
  address?: string,
  claimAmount?: number,
  maxClaim?: number,
  onSuccess?: () => void
): [() => void, boolean | undefined] => {
  const markleproof = useMemo(() => {
    if (address && maxClaim) {
      const leaves = goldlist.map(e => soliditySha3(...e));
      const tree = new MerkleTree(leaves, keccak256, { sortPairs: true });
      return tree.getHexProof(soliditySha3(...[address, maxClaim]) as string);
    }
  }, [address, maxClaim]);

  const [
    { data: mintData, loading: isLoading, error: writeError },
    mintGoldlist,
  ] = useContractWrite(
    {
      addressOrName: honeyJarsContract.address,
      contractInterface: honeyJarsContract.interface,
    },
    'mintGoldList',
    {
      args: [markleproof, maxClaim, claimAmount],
    }
  );

  const [{ data: waitData, loading: isWaiting, error: waitError }] =
    useWaitForTransaction({
      wait: mintData?.wait,
    });

  useEffect(() => {
    if (!isWaiting && waitData && onSuccess) {
      onSuccess();
    }
  }, [isWaiting, waitData, onSuccess]);

  useSnackbarError(writeError);
  useSnackbarError(waitError);
  useSnackbarSuccess(waitData);

  return useMemo(
    () => [mintGoldlist, isLoading || isWaiting],
    [mintGoldlist, isWaiting, isLoading]
  );
};

export default useGoldlistMint;
