import en from './locales/en.json';
import honeyJarsNftAbi from './abis/honeyJars.abi.json';
import mainnetGoldlist from './goldlist.mainnet.json';
import testnetGoldlist from './goldlist.testnet.json';
import { chain } from 'wagmi';

export { default as theme } from './theme';

export const fancyBearsOpenSeaUrl =
  process.env.REACT_APP_FANCY_BEARS_OPENSEA_URL;
export const honeyJarsOpenSeaUrl = process.env.REACT_APP_HONEY_JARS_OPENSEA_URL;

export const chainName = process.env.REACT_APP_CHAIN_NAME;
export const infuraId = process.env.REACT_APP_INFURA_ID;

// @ts-expect-error
export const chains = [chain[process.env.REACT_APP_CHAIN_NAME!]];

export const honeyJarsContract = {
  address: process.env.REACT_APP_HONEY_JARS_ADDRESS!,
  interface: honeyJarsNftAbi,
};

export const locales = {
  en,
};

export const goldlist = (
  chainName === 'goerli' ? testnetGoldlist : mainnetGoldlist
) as [address: string, maxCount: number][];

export const learnMoreUrl = process.env.REACT_APP_LEARN_MORE_URL;
