import { honeyJarsContract } from 'config';
import { useEffect, useState } from 'react';
import { useContractRead } from 'wagmi';

const useClaimActive = (): boolean | undefined => {
  const [isClaimActive, setIsClaimActive] = useState<boolean>();
  const [{ data: claimActiveData }] = useContractRead(
    {
      addressOrName: honeyJarsContract.address,
      contractInterface: honeyJarsContract.interface,
    },
    'claimActive'
  );

  useEffect(() => {
    if (claimActiveData !== undefined) {
      setIsClaimActive(!!claimActiveData);
    }
  }, [claimActiveData]);

  return isClaimActive;
};

export default useClaimActive;
