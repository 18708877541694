import AssetsContext, { AssetsContextValue } from 'contexts/AssetsContext';
import useConnectedAccount from 'hooks/useConnectedAccount';
import useGoldlistClaimedCount from 'hooks/useGoldlistClaimedCount';
import useGoldlistEntry from 'hooks/useGoldlistEntry';
import { FunctionComponent, useMemo } from 'react';
import { useBalance } from 'wagmi';

const AssetsProvider: FunctionComponent = ({ children }) => {
  const [address] = useConnectedAccount();

  const [goldlistClaimedCount, readGoldlistClaimedCount] =
    useGoldlistClaimedCount(address);

  const goldlistEntry = useGoldlistEntry(address);

  const [{ data: balance }] = useBalance({
    addressOrName: address,
  });

  const contextValue = useMemo<AssetsContextValue>(
    () => ({
      balance: balance?.value,
      goldlistClaimedCount,
      goldlistEntry,
      readGoldlistClaimedCount,
    }),
    [balance, goldlistClaimedCount, goldlistEntry, readGoldlistClaimedCount]
  );

  return (
    <AssetsContext.Provider value={contextValue}>
      {children}
    </AssetsContext.Provider>
  );
};

export default AssetsProvider;
