import BoldSpinner from 'components/BoldSpinner';
import ErrorDetails from 'components/ErrorDetails';
import useAssets from 'hooks/useAssets';
import useConnectedAccount from 'hooks/useConnectedAccount';
import useGoldlistMint from 'hooks/useGoldlistMint';
import useTranslate from 'hooks/useTranslate';
import { useCallback, useMemo } from 'react';
import {
  Badge,
  Box,
  Button,
  Center,
  Container,
  Heading,
  Text,
  VStack,
} from '@chakra-ui/react';

const GoldlistContainer = () => {
  const translate = useTranslate();
  const [address] = useConnectedAccount();

  let { goldlistClaimedCount, readGoldlistClaimedCount, goldlistEntry } =
    useAssets();

  let availableToClaim = useMemo(() => {
    if (goldlistEntry && goldlistClaimedCount !== undefined) {
      return goldlistEntry[1] - goldlistClaimedCount;
    }
    return 0;
  }, [goldlistEntry, goldlistClaimedCount]);

  const handleMintGoldlistSuccess = useCallback(() => {
    readGoldlistClaimedCount();
  }, [readGoldlistClaimedCount]);

  const [mintGoldlist, isGoldlistMinting] = useGoldlistMint(
    address,
    availableToClaim,
    goldlistEntry?.[1],
    handleMintGoldlistSuccess
  );

  const handleClaim = useCallback(() => {
    mintGoldlist();
  }, [mintGoldlist]);

  return (
    <Container maxW="sm">
      <Box bg="dark.800" py="16" px="8" w="full" borderRadius="lg">
        {availableToClaim > 0 ? (
          <VStack spacing="8" align="center">
            <VStack w="full" spacing="1">
              <Heading fontSize="4xl" fontWeight="800">
                {translate('goldlist:title')}
              </Heading>

              <Text opacity="0.8">{translate('goldlist:description')}</Text>
            </VStack>

            <Button
              onClick={handleClaim}
              isLoading={isGoldlistMinting}
              size="lg"
              px="12"
            >
              {availableToClaim
                ? availableToClaim > 1
                  ? translate('common:claim:multi', { count: availableToClaim })
                  : translate('common:claim:one')
                : translate('common:claim:none')}
            </Button>
          </VStack>
        ) : (
          <>
            {goldlistClaimedCount === undefined && (
              <Center>
                <BoldSpinner size="xl" />
              </Center>
            )}
            {goldlistEntry === undefined && (
              <ErrorDetails
                title={translate('goldlist:entryNotFound')}
                description={
                  <Badge
                    bg="dark.500"
                    color="white"
                    textTransform="none"
                    fontWeight="normal"
                  >
                    {address}
                  </Badge>
                }
              />
            )}
            {goldlistEntry !== undefined && goldlistClaimedCount !== undefined && (
              <ErrorDetails
                title={translate('goldlist:allMinted')}
                description={
                  <Badge
                    bg="dark.500"
                    color="white"
                    textTransform="none"
                    fontWeight="normal"
                  >
                    {address}
                  </Badge>
                }
              />
            )}
          </>
        )}
      </Box>
    </Container>
  );
};

export default GoldlistContainer;
