import AssetsProvider from 'providers/AssetsProvider';
import GoldlistContainer from 'components/GoldlistContainer';
import Header from 'components/Header';
import React from 'react';
import { Center } from '@chakra-ui/react';

const HomePage: React.FC = () => (
  <AssetsProvider>
    <Header />

    <Center pt="8" pb="20" h="full">
      <GoldlistContainer />
    </Center>
  </AssetsProvider>
);

export default HomePage;
