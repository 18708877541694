import React from 'react';
import { BigNumber } from 'ethers';

export type AssetsContextValue = {
  balance?: BigNumber;
  goldlistClaimedCount?: number;
  goldlistEntry?: [address: string, maxClaim: number];
  readGoldlistClaimedCount: () => void;
};

const AssetsContext = React.createContext<AssetsContextValue>({
  balance: undefined,
  goldlistClaimedCount: undefined,
  goldlistEntry: undefined,
  readGoldlistClaimedCount: () => {},
});

export default AssetsContext;
