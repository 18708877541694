import React, { useCallback } from 'react';
import {
  InputGroup,
  InputGroupProps,
  Input,
  InputRightElement,
  IconButton,
  Icon,
  useClipboard,
  Tooltip,
} from '@chakra-ui/react';
import { FaRegCopy } from 'react-icons/fa';
import useTranslate from 'hooks/useTranslate';
import useSnackbar from 'hooks/useSnackbar';

export interface CopyInputProps extends InputGroupProps {
  value: string;
}

const CopyInput: React.FC<CopyInputProps> = ({ value, ...rest }) => {
  const translate = useTranslate();
  const label = translate('common:copy');
  const { onCopy: copy } = useClipboard(value);
  const snackbar = useSnackbar();

  const handleCopy = useCallback(() => {
    copy();
    snackbar('success', translate('common:copied'));
  }, [copy, snackbar, translate]);

  return (
    <InputGroup {...rest}>
      <Input size="lg" value={value} isDisabled opacity="1 !important" />

      <InputRightElement h="full">
        <Tooltip label={label} placement="top">
          <IconButton
            minW="8"
            maxW="8"
            maxH="8"
            aria-label={label}
            lineHeight="none"
            icon={<Icon as={FaRegCopy} w="5" h="auto" />}
            variant="unstyled"
            onClick={handleCopy}
            _hover={{ color: 'secondary.500' }}
          />
        </Tooltip>
      </InputRightElement>
    </InputGroup>
  );
};

export default CopyInput;
