import React, { useState } from 'react';
import { Box, Button, Container, HStack, Text } from '@chakra-ui/react';
import Logo from 'components/Logo';
import { getShortenedAddress } from 'utils/walletUtils';
import useConnectedAccount from 'hooks/useConnectedAccount';
import WalletPopup from 'components/WalletPopup';

const Header: React.FC = () => {
  const [address] = useConnectedAccount();
  const [isWalletDialogVisible, setIsWalletDialogVisible] = useState(false);

  return (
    <>
      <Box as="header" bg="dark.900" py="4">
        <Container>
          <HStack justify="space-between" overflow="hidden">
            <Logo width="7em" height="auto" />

            {address && (
              <HStack spacing="6">
                <Button
                  borderColor="dark.600"
                  _hover={{
                    borderColor: 'secondary.500',
                  }}
                  colorScheme="bright"
                  borderRadius="full"
                  variant="outline"
                  size="sm"
                  onClick={() => setIsWalletDialogVisible(true)}
                >
                  <Text fontWeight="semibold" fontSize="sm">
                    {getShortenedAddress(address, 3)}
                  </Text>
                </Button>
              </HStack>
            )}
          </HStack>
        </Container>
      </Box>

      {address && (
        <WalletPopup
          isOpen={isWalletDialogVisible}
          onClose={() => setIsWalletDialogVisible(false)}
        />
      )}
    </>
  );
};

export default Header;
